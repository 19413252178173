@import '@/styles/styles';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentContainer {
  margin: 0 auto;

  @include layout();
}
