@import '@/styles/styles';

.layout {
  position: relative;
  @include layout;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  @include media-breakpoint-down(md) {
    padding: 64px 25px;
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 558px;
  padding: 0 25px;
  position: relative;
  margin-top: auto;
}

.background {
  background-color: $primary;
  height: 100%;
  left: 0;
  margin-left: calc(50% - 50vw);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.title {
  font-family: $title-font-family;
  font-size: 64px;
  font-weight: 400;
  line-height: 64px;
  margin-bottom: 7px;
  text-align: center;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 32px;
  text-align: center;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid rgba($color: $text-color-primary-dark, $alpha: 0.5);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    padding: 8px 0;
    font-family: $body-font-family;
    &::placeholder {
      color: rgba($color: $text-color-primary-dark, $alpha: 0.5);
    }

    &:focus-visible {
      outline: none;
    }
    &.error {
      border-bottom: solid 1px red;
    }
  }
}

.half {
  width: 50%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.row {
  width: 100%;
  display: flex;
  gap: 16px;
  @include media-breakpoint-down(sm) {
    gap: 0;
  }
}

.successMessage {
  white-space: pre-line;
  font-family: $title-font-family;
  font-size: 64px;
  font-weight: 400;
  line-height: 64px;
  margin-bottom: 7px;
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 40px;
    line-height: 48px;
  }
}
